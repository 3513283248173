// components/Home.js
import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const searchHistoryData = [
    {
      id: 1,
      query: "History 1",
      resultCount: 10,
      date: "2022-07-01",
      client: "Client 1",
    },
    {
      id: 2,
      query: "History 2",
      resultCount: 8,
      date: "2022-07-02",
      client: "Client 2",
    },
    // ... more data
  ];
  const clientsData = [
    {
      id: 1,
      name: "Client 1",
      email: "client1@example.com",
      status: "Active",
      lastSearch: "View Results",
    },
    {
      id: 2,
      name: "Client 2",
      email: "client2@example.com",
      status: "Inactive",
      lastSearch: "View Results",
    },
    // ... more data
  ];
  const propertiesData = [
    {
      id: 1,
      address: "123 Main St",
      owner: "Owner 1",
      value: "$300,000",
      lastUpdated: "2022-07-01",
    },
    {
      id: 2,
      address: "456 Elm St",
      owner: "Owner 2",
      value: "$250,000",
      lastUpdated: "2022-07-02",
    },
    // ... more data
  ];

  // Function to render a table dynamically
  const renderTable = (data, headers) => {
    return (
      <table className="min-w-full">
        <thead>
          <tr>
            {Object.keys(headers).map((key) => (
              <th key={key} className="px-4 py-2">
                {headers[key]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.slice(0, 5).map((item) => (
            <tr key={item.id}>
              {Object.keys(headers).map((key) => (
                <td key={`${item.id}-${key}`} className="border px-4 py-2">
                  {item[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const searchHistoryHeaders = {
    query: "Query",
    resultCount: "Results",
    client: "Client",
    date: "Date",
  };
  const clientsHeaders = {
    name: "Name",
    email: "Email",
    status: "Status",
    lastSearch: "Last Search",
  };
  const propertiesHeaders = {
    address: "Address",
    owner: "Owner",
    value: "Value",
    lastUpdated: "Last Updated",
  };

  return (
    <div className="container p-4 flex flex-col gap-4">
      <div className="p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-3">Actions</h3>
        <div>
          <Link
            to="/search"
            className="mt-3 inline-block bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
          >
            Start New Search
          </Link>
        </div>
      </div>

      <div className="p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-3">Search History</h3>
        {renderTable(searchHistoryData, searchHistoryHeaders)}
        <div className="flex justify-between">
          <Link
            to="/searches"
            className="mt-3 inline-block bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
          >
            View All
          </Link>
        </div>
      </div>

      <div className="p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-3">Clients</h3>
        {renderTable(clientsData, clientsHeaders)}
        <Link
          to="/clients"
          className="mt-3 inline-block bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
        >
          View All
        </Link>
      </div>

      <div className="p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-3">Properties</h3>
        {renderTable(propertiesData, propertiesHeaders)}
        <Link
          to="/properties"
          className="mt-3 inline-block bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
        >
          View All
        </Link>
      </div>
    </div>
  );
};

export default Home;
