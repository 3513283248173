// userStore.js
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

const useStore = create(
  subscribeWithSelector((set, get) => ({
    user: JSON.parse(localStorage.getItem("user")) || null, // Initialize state from localStorage

    setUser: (userData) => {
      localStorage.setItem("user", JSON.stringify(userData)); // Update localStorage when user is set
      set({ user: userData });
    },

    checkUser: () => {
      const user = localStorage.getItem("user");
      return !!user;
    },
  }))
);

// Subscribe to changes in the user state to update local storage
useStore.subscribe(
  (state) => state.user,
  (user) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }
);

export default useStore;
