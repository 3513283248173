// components/IndividualSearch.js
import React from "react";
import { useParams } from "react-router-dom";

const IndividualSearch = () => {
  const { id } = useParams();

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Search Details: {id}</h2>
      {/* Details of the individual search go here */}
    </div>
  );
};

export default IndividualSearch;
