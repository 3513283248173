import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
let bool = true;
const Input = styled("input")({
  display: "none",
});
const ITEM_HEIGHT = 48;
const Document = ({}) => {
  const query = useQuery();
  console.log(atob(query.get("documentUrl")).split("/"))
  const [routeArray, setRouteArray] = useState(decodeURIComponent(decodeURIComponent(atob(query.get("documentUrl"))).split("/").map((x, index) => { if(index == decodeURIComponent(atob(query.get("documentUrl"))).split("/").length -1) { return x  } return x +"/" } )));
  const [listFolderAndFile, setListFolderAndFile] = useState("");
  const [isLoader, setIsLoader] = React.useState(false);
    React.useState(false);
  const testParam = decodeURIComponent(atob(query.get("documentUrl")));
  // const handleChange = (file) => {
  //   setFile(file);
  // };

  // const handleFolderChange = (event) => {
  //   const files = event.target.files;
  //   const filesArray = Array.from(files);
  //   setSelectedFiles(filesArray);
  // }
  const listAll = async () => {
    try {
      setIsLoader(true);
      const url = decodeURIComponent(atob(query.get("documentUrl")));
const routeTemp = url.split("/").map((x, index, arr) => {
    if (index === arr.length - 1) {
        return x; 
    }
    return x + "/"; 
});
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/getFileUrl`, {
          folderPath: bool ? testParam : routeTemp,
        })
        .then(async (res) => {
          bool = false;
          setListFolderAndFile(res.data?.items);
          console.log("File uploaded successfully:", res.data);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    listAll();
  }, [routeArray]);


  return (
    <div style={{ display: 'flex', width: '100%', height: '100%', position: 'absolute' }}>
      <iframe src={listFolderAndFile}  style={{ width: '100%', height: '100%' }}></iframe>
    </div>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default Document;
