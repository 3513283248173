// components/Clients.js
import React from "react";

const Clients = () => {
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Clients</h2>
      {/* Client management functionality goes here */}
    </div>
  );
};

export default Clients;
