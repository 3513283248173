import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import useStore from "../store";
import { Link } from "react-router-dom";
import ProrationCalculator from "./prorationCalculator";
import image from "../images/menu.png";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from '@mui/material/Popover';
import CircularProgress from "@mui/material/CircularProgress";
import Modal from '@mui/material/Modal';
import axios from "axios";
const Navbar = () => {
  const setUser = useStore((state) => state.setUser);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };
  const [modalLoader, setModalLoader] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleClick = () => {
    setIsDropdownOpen(true);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
  };
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const updateRating = async() => {
    setModalLoader(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/getPlaceIdProperties"
      ).then(async (res) =>{
        console.log(res.data);
        for (let item of res.data) {
          const response = await axios
            .get(
              process.env.REACT_APP_API_URL + "/upload-google-rating/" + item.id
            )
            .then((res) => {})
            .catch((error) => {
              setModalLoader(false);
              console.error("Error:", error.response.data);
            });
        }   
        setModalLoader(false);   
      })
      .catch((error) => {
        setModalLoader(false);  
        console.error("Error:", error.response.data);
      });
    
  }
  return (
    <div
      style={{ zIndex: "10", position: "sticky", top: 0 }}
      className="  bg-sidekick-primary p-4  text-white flex flex-row justify-between "
    >
      <nav>
        <ul className="flex flex-row gap-[1px] md:gap-[13px] lg:gap-[50px] xl:gap-[70px]">
          <li
            className="md:hidden mt-1  opacity-100"
            onClick={handleDrawerOpen}
          >
            <img width={20} src={image} alt="Menu" />
          </li>

          <li className="text-2xl px-3 font-bold">
            <Link to="/" className="hover:text-gray-300 ">
              Sidekick
            </Link>{" "}
          </li>
          <li className="hidden md:block">
            <Link
              to="/search"
              className="block  hover:opacity-75 mt-1 rounded px-4 "
            >
              New Search
            </Link>
          </li>
          <li className="hidden md:block">
            <Link
              to="/searches"
              className="block hover:opacity-75 mt-1  rounded px-4"
            >
              Search History
            </Link>
          </li>
          <li className="hidden md:block">
            <Link
              to="/clients"
              className="block  hover:opacity-75 mt-1 rounded px-4"
            >
              Clients
            </Link>
          </li>
          <li className="hidden md:block">
            <Link
              to="/properties"
              className="block  hover:opacity-75  mt-1 rounded px-4"
            >
              Properties
            </Link>
          </li>
          <li className="hidden md:block">
            <div
              onClick={handleClick}
              role="button"
              variant="contained"
              className="mt-1 hover:opacity-75 "
            >
              Proration
            </div>
            <Popover
              open={isDropdownOpen}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              className="mt-[40px] md:ml-[140px] lg:ml-[250px] xl:ml-[130px] xxl:ml-[-130px]"
            >
              <div className="p-2 rounded-xl bg-white text-black">
                <ProrationCalculator />
              </div>
            </Popover>
          </li>
          <li className="hidden md:block">
            <Link
              to="/file-storage"
              className="block  hover:opacity-75  mt-1 rounded px-4"
            >
              Drive
            </Link>
          </li>
          <li className="hidden md:block" onClick={updateRating}>
            <Link              
              className="block  hover:opacity-75  mt-1 rounded px-4"
            >
              Update Google Rating
            </Link>
          </li>
        </ul>
      </nav>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        className="mt-[40px] md:ml-[140px] lg:ml-[250px] xl:ml-[130px] xxl:ml-[-130px] "
      >
        <List className=" bg-sidekick-primary text-white">
          <ListItem
            button
            component={Link}
            to="/search"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="New Search" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/searches"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Search History" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/clients"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Clients" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/properties"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Properties" />
          </ListItem>
        </List>
        <Divider />
        <List className=" bg-sidekick-primary text-white">
          <ListItem button onClick={handleDrawerClose}>
            <div className="p-2 rounded-xl bg-white text-black">
              <ProrationCalculator />
            </div>
          </ListItem>
        </List>
      </Drawer>
      <button
        onClick={handleLogout}
        className=" bg-sidekick-tertiary hover:bg-opacity-80 py-1 text-white rounded px-4  self-start"
      >
        Logout
      </button>
      <Modal open={modalLoader}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress disableShrink />
            </div>
            <div style={{ color: 'white' }}>Updatating google rating is in progress. Please wait and do not go back.</div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Navbar;
