import React, { useState, useEffect } from "react";
import axios from "axios";
import folderImage from "../../images/macFolder.png";
import fileIcon from "../../images/fileIcon.png";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import videoIcon from "../../images/video.png";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Preview } from "@mui/icons-material";
import { Button, TextField, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
//import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";
let bool = true;
const Input = styled("input")({
  display: "none",
});
const ITEM_HEIGHT = 48;
const Folder = ({}) => {
  const query = useQuery(); 
  const [sortState, setSortState] = useState("normal");
  const [sortType, setSortType] = useState("normal");
  const [sortDate, setSortDate] = useState("normal");
  const [currentFormat, setCurrentFormat] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [showRename, setShowRename] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({});
  const [createNewFolderName, setCreateNewFolderName] = useState("");
  const [file, setFile] = useState(null);
  const [routeArray, setRouteArray] = useState(decodeURIComponent(atob(query.get("folderUrl"))).replace(/\/{2,}/g, "/").slice(0, -1).split("/").map((x) => x + "/"));
  const [disableFolder, setDisableFolder] = useState(decodeURIComponent(atob(query.get("folderUrl"))).slice(0, -1).split("/").map((x) => x + "/").length);
  const [listFolderAndFile, setListFolderAndFile] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorAddEl, setAnchorAddEl] = React.useState(null);
  const [isLoader, setIsLoader] = React.useState(false);
  const [modalThumbnailFileOpen, setOpenModalThumbnailFile] =
    React.useState(false);
  const [showDownload, setShowDownload] = React.useState(true);
  const [showThumbnail, setShowThumbnail] = React.useState(true);
  const fileTypes = ["JPEG", "PNG", "GIF", "PDF", "DOCX", "TXT"];
  const navigation = useNavigate();
  const testParam = decodeURIComponent(atob(query.get("folderUrl")));
  // const handleChange = (file) => {
  //   setFile(file);
  // };

  // const handleFolderChange = (event) => {
  //   const files = event.target.files;
  //   const filesArray = Array.from(files);
  //   setSelectedFiles(filesArray);
  // };

  const uploadBatch = async (filesBatch) => {
    const formData = new FormData();
    filesBatch.forEach((file) => {
      formData.append('files', file, file.webkitRelativePath || file.name);
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/uploadFolder`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); 
    } catch (error) {
      console.error('Error uploading batch:', error);
      throw error;
    }
  };
  const open = Boolean(anchorEl);
  const openAdd = Boolean(anchorAddEl);
  const handleClick = (event, data) => {
    setCurrentFolder(data);
    if (data?.type === "folder") {
      setShowThumbnail(true);
      setShowDownload(true);
      setOptions((prev) => ["Share"]);
    } else if (data?.type === "video") {
      setShowThumbnail(false);
      setShowDownload(false);
      setOptions((prev) => [
        "Share",
        "Download",
      ]);
    } else if (data?.type === "document") {
      setShowThumbnail(true);
      setShowDownload(false);
      setOptions((prev) => ["Share", "Download"]);
    } else if (data?.type === "image") {
      setShowThumbnail(true);
      setShowDownload(false);
      setOptions((prev) => ["Download"]);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const deleteFolder = async () => {
    try {
      const response = await axios
        .put(`${process.env.REACT_APP_API_URL}/deleteFolder`, {
          folderName: currentFolder,
          path: routeArray,
        })
        .then((response) => {
          setAnchorEl(null);
          listAll();
        });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const downloadFile = async () => {
    try {
      if (currentFolder?.type == "image") {
        // const response = await fetch(currentFolder.downloadUrl);
        // const blob = await response.blob();
        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = currentFolder.name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        const link = document.createElement("a");
        link.href = currentFolder.downloadUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      }
      else if(currentFolder?.type == "video") 
        {
          const a = document.createElement("a");
          a.href = currentFolder.downloadUrl;
          a.download = currentFolder.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
       else {
        const response = await fetch(currentFolder.downloadUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = currentFolder.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const addThumbnail = async () => {
    setOpenModalThumbnailFile(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}-${day}-${year} ${hours}:${minutes}`;
  };

  const renameFile = async () => {
    try {
      const result = currentFolder.name.replace(/\/$/, "");
      let temp = result;
      let temp1 = temp.split(".");
      temp1.splice(temp1.length - 1, 1);
      let temp2 = temp1.join(",");
      let payloadFile = temp2;
      if (currentFolder.type != "folder")
        setCurrentFormat(temp.split(".")[temp.split(".").length - 1]);
      else setCurrentFormat("");
      setCreateNewFolderName(payloadFile);
      setShowRename(true);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const shareFolder = async () => {
    try {
      let utfUrl =
        currentFolder.type == "folder"
          ? window.location.origin +
            "/folder?folderUrl=" +
            btoa(
              routeArray.join("/")?.replace(/\/{2,}/g, "/") +
                encodeURIComponent(currentFolder?.name) +
                "/"
            )
          : currentFolder.type == "document" || currentFolder.type == "image"
          ? window.location.origin +
          "/" + currentFolder.type +"?documentUrl=" +
          btoa(
            routeArray.join("/")?.replace(/\/{2,}/g, "/") +
              encodeURIComponent(currentFolder?.name)
          )
          : window.location.origin +
            "/video?videoUrl=" +
            btoa(
              routeArray.join("/")?.replace(/\/{2,}/g, "/") +
                encodeURIComponent(currentFolder?.name)
            );


      const plainTextContent = utfUrl;

      navigator.clipboard
        .write([
          new ClipboardItem({
            "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
          }),
        ])
        .then(() => {
          console.log("Email info copied to clipboard!");
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error("Failed to copy email info: ", err);
        });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevents the snackbar from closing when the user clicks outside of it
    }
    setSnackbarOpen(false); // Close the Snackbar
  };

  const listAll = async () => {
    try {
      setIsLoader(true);
      let routeTemp = routeArray?.join("/")?.replace("//", "/");
      if (!routeTemp?.endsWith("/") && routeTemp != "") {
        routeTemp = routeTemp + "/";
      }
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/listAll`, {
          folderPath: bool ? testParam : routeTemp,
        })
        .then(async (res) => {
          bool = false;
          for (let item of res.data?.items) {
            if (item.type == "video") {
              const blobUrl = await createBlobUrl(item.downloadUrl);
              break;
            }
          }
          setIsLoader(false);
          setListFolderAndFile([]);
          setListFolderAndFile(res?.data?.items || []);
          console.log("File uploaded successfully:", res.data);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    listAll();
  }, [routeArray]);

  const createBlobUrl = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error creating Blob URL:", error);
      return null;
    }
  };

  return (
    <div>
      <div className="flex" style={{ flexWrap: "wrap", width: '100%' }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            {routeArray.length > 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button onClick={() => setRouteArray([])} disabled={true}>
                  <FolderIcon style={{ color: "#8f8f8f", fontSize: "40px" }} />
                </button>
              </div>
            )}
            {routeArray?.map((x, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <KeyboardDoubleArrowRightIcon
                  style={{ color: "#8f8f8f", fontSize: "30px" }}
                />
                <button
                disabled={index < disableFolder - 1}
                  style={{ fontSize: "20px" }}
                  onClick={(e) => {
                    setRouteArray((prevArray) => {
                      const updatedArray = [...prevArray];
                      updatedArray?.splice(index + 1, updatedArray.length);
                      return updatedArray;
                    });
                  }}
                >
                  {x?.replace(/\//g, "")}
                </button>
              </div>
            ))}
          </div>
          {/* <div style={{ padding: "10px" }}> 
            <Button
              value="Upload Folder"
              onClick={handleAddClose}
              variant="contained"
            >
              Upload Folder
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <Button
              value="Add Folder"
              onClick={handleAddClose}
              variant="contained"
            >
              Add Folder
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              value="Upload File"
              onClick={handleAddClose}
              variant="contained"
            >
              Upload File
            </Button> 
          </div> */}
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="flex flex-col"
            style={{
              margin: "10px",
              boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              display: "flex",
              padding: "10px",
            }}
          >
            <div
              style={{
                paddingRight: "10px",
                paddingLeft: "20px",
                marginBottom: "-7px",
                display: "flex",
              }}
            >
              <button style={{ width: "3%" }}></button>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "25%",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "20px",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <button
                    onClick={() => {
                      if (sortState == "normal") setSortState("asc");
                      else if (sortState == "asc") setSortState("desc");
                      else if (sortState === "desc") setSortState("normal");
                      setSortType("normal");
                      setSortDate("normal");
                    }}
                  >
                    Name
                    {sortState == "normal" && <SwapVertIcon />}
                    {sortState == "asc" && <ArrowUpwardIcon />}
                    {sortState == "desc" && <ArrowDownwardIcon />}
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "10px",
                  width: "24%",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "20px",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <button
                    onClick={() => {
                      if (sortType == "normal") setSortType("asc");
                      else if (sortType == "asc") setSortType("desc");
                      else if (sortType === "desc") setSortType("normal");
                      setSortState("normal");
                      setSortDate("normal");
                    }}
                  >
                    Type
                    {sortType == "normal" && <SwapVertIcon />}
                    {sortType == "asc" && <ArrowUpwardIcon />}
                    {sortType == "desc" && <ArrowDownwardIcon />}
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "10px",
                  width: "24%",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "20px",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <button
                    onClick={() => {
                      if (sortDate == "normal") setSortDate("asc");
                      else if (sortDate == "asc") setSortDate("desc");
                      else if (sortDate === "desc") setSortDate("normal");
                      setSortType("normal");
                      setSortState("normal");
                    }}
                  >
                    Date Modified
                    {sortDate == "normal" && <SwapVertIcon />}
                    {sortDate == "asc" && <ArrowUpwardIcon />}
                    {sortDate == "desc" && <ArrowDownwardIcon />}
                  </button>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  width: "24%",
                }}
              ></div>
            </div>
          </div>
        </div>
        {sortState == "normal" &&
          sortType == "normal" &&
          sortDate == "normal" && (
            <div style={{ width: "100%" }}>
              {listFolderAndFile &&
                listFolderAndFile?.length > 0 &&
                listFolderAndFile
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <div>
                      {x?.type == "folder" && x?.name != "" && (
                        <div
                          className="flex flex-col"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            display: "flex",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              marginBottom: "-7px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <button
                              style={{ width: "3%" }}
                              folderType={x?.type}
                              folderName={x?.name}
                              onClick={(e) => {
                                if (
                                  e?.currentTarget?.getAttribute(
                                    "folderType"
                                  ) == "folder"
                                ) {
                                  setRouteArray((prevArray) => [
                                    ...prevArray,
                                    e?.currentTarget?.getAttribute(
                                      "folderName"
                                    ) + "/",
                                  ]);
                                  console.log(routeArray);
                                }
                              }}
                            >
                              <img
                                src={folderImage}
                                style={{ width: "40px" }}
                              />
                            </button>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                width: "25%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {x?.name?.replace("/", "")}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: "10px",
                                width: "24%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {x?.type
                                  ?.replace("/", "")
                                  .charAt(0)
                                  .toUpperCase() +
                                  x?.type?.replace("/", "").slice(1)}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: "10px",
                                width: "24%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                <button
                                // onClick={() => {
                                //   if (sortType == "normal") setSortType("asc");
                                //   else if (sortType == "asc")
                                //     setSortType("desc");
                                //   else if (sortType === "desc")
                                //     setSortType("normal");
                                //   setSortState("normal");
                                //   setSortDate("normal");
                                // }}
                                >
                                  {formatDate(x?.lastModified)}
                                </button>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                width: "24%"
                              }}
                            >
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                  handleClick(e, x);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

              {listFolderAndFile &&
                listFolderAndFile?.length > 0 &&
                listFolderAndFile
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <div>
                      {x?.type == "video" && x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            alignItems: "center",
                            padding: "8px",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={videoIcon}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div style={{ width: "25%" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "20px",
                                paddingLeft: "10px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                                onClick={() => {
                                  if (sortType == "normal") setSortType("asc");
                                  else if (sortType == "asc")
                                    setSortType("desc");
                                  else if (sortType === "desc")
                                    setSortType("normal");
                                  setSortState("normal");
                                  setSortDate("normal");
                                }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

              {listFolderAndFile &&
                listFolderAndFile?.length > 0 &&
                listFolderAndFile
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <div>
                      {x?.type == "image" &&
                        x?.name != "" &&
                        !x?.name?.includes("-thumb-") && (
                          <div
                            className="flex"
                            style={{
                              margin: "10px",
                              boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                              borderRadius: "10px",
                              padding: "9px",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                paddingRight: "10px",
                                paddingLeft: "20px",
                                display: "flex",
                                alignItems: "center",
                                width: "3%",
                              }}
                            >
                              <img
                                src={x?.downloadUrl}
                                style={{ height: "31px", width: "50px" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "20px",
                                paddingLeft: "10px",
                                width: "25%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {x?.name?.replace("/", "")}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: "10px",
                                width: "24%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {x?.type
                                  ?.replace("/", "")
                                  .charAt(0)
                                  .toUpperCase() +
                                  x?.type?.replace("/", "").slice(1)}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: "10px",
                                width: "24%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                <button
                                // onClick={() => {
                                //   if (sortType == "normal") setSortType("asc");
                                //   else if (sortType == "asc")
                                //     setSortType("desc");
                                //   else if (sortType === "desc")
                                //     setSortType("normal");
                                //   setSortState("normal");
                                //   setSortDate("normal");
                                // }}
                                >
                                  {formatDate(x?.lastModified)}
                                </button>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "24%",
                                display: "flex",
                                justifyContent: "end",
                                paddingRight: "10px",
                              }}
                            >
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                  handleClick(e, x);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}

              {listFolderAndFile &&
                listFolderAndFile?.length > 0 &&
                listFolderAndFile
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <div>
                      {(x?.type == "document" || x?.type == "file") &&
                        x?.name != "" && (
                          <div
                            className="flex"
                            style={{
                              margin: "10px",
                              boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                              borderRadius: "10px",
                              padding: "9px",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                paddingRight: "10px",
                                paddingLeft: "20px",
                                height: "100%",
                                width: "3%",
                              }}
                            >
                              <img
                                src={fileIcon}
                                style={{ width: "50px", height: "31px" }}
                              />
                            </div>
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                                fontSize: "20px",
                                paddingLeft: "10px",
                                width: "25%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100px",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  height: "100%",
                                }}
                              >
                                {x?.name?.replace("/", "")}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: "10px",
                                width: "24%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {x?.type
                                  ?.replace("/", "")
                                  .charAt(0)
                                  .toUpperCase() +
                                  x?.type?.replace("/", "").slice(1)}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginLeft: "10px",
                                width: "24%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "20px",
                                  paddingLeft: "10px",
                                }}
                              >
                                <button
                                // onClick={() => {
                                //   if (sortType == "normal") setSortType("asc");
                                //   else if (sortType == "asc")
                                //     setSortType("desc");
                                //   else if (sortType === "desc")
                                //     setSortType("normal");
                                //   setSortState("normal");
                                //   setSortDate("normal");
                                // }}
                                >
                                  {formatDate(x?.lastModified)}
                                </button>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "24%",
                                display: "flex",
                                justifyContent: "end",
                                paddingRight: "10px",
                              }}
                            >
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                  handleClick(e, x);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
            </div>
          )}
        {sortState == "asc" && (
          <div style={{ width: "100%" }}>
            {listFolderAndFile &&
              listFolderAndFile?.length > 0 &&
              listFolderAndFile
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <div>
                    {x?.type == "folder" && x?.name != "" && (
                      <div
                        className="flex flex-col"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          display: "flex",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            marginBottom: "-7px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <button
                            style={{ width: "3%" }}
                            folderType={x?.type}
                            folderName={x?.name}
                            onClick={(e) => {
                              if (
                                e?.currentTarget?.getAttribute("folderType") ==
                                "folder"
                              ) {
                                setRouteArray((prevArray) => [
                                  ...prevArray,
                                  e?.currentTarget?.getAttribute("folderName") +
                                    "/",
                                ]);
                                console.log(routeArray);
                              }
                            }}
                          >
                            <img src={folderImage} style={{ width: "40px" }} />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {x?.type == "video" && x?.name != "" && (
                      <div
                        className="flex"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          alignItems: "center",
                          padding: "8px",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            width: "3%",
                          }}
                        >
                          <img
                            src={videoIcon}
                            style={{ height: "31px", width: "50px" }}
                          />
                        </div>
                        <div style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {x?.name?.replace("/", "")}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            {x?.type?.replace("/", "").charAt(0).toUpperCase() +
                              x?.type?.replace("/", "").slice(1)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            <button
                              onClick={() => {
                                if (sortType == "normal") setSortType("asc");
                                else if (sortType == "asc") setSortType("desc");
                                else if (sortType === "desc")
                                  setSortType("normal");
                                setSortState("normal");
                                setSortDate("normal");
                              }}
                            >
                              {formatDate(x?.lastModified)}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "24%",
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, x);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {x?.type == "image" &&
                      x?.name != "" &&
                      !x?.name?.includes("-thumb-") && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={x?.downloadUrl}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    {(x?.type == "document" || x?.type == "file") &&
                      x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              height: "100%",
                              width: "3%",
                            }}
                          >
                            <img
                              src={fileIcon}
                              style={{ width: "50px", height: "31px" }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                height: "100%",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
          </div>
        )}
        {sortState == "desc" && (
          <div style={{ width: "100%" }}>
            {listFolderAndFile &&
              listFolderAndFile?.length > 0 &&
              listFolderAndFile
                .sort((a, b) => b.name.localeCompare(a.name))
                .map((x) => (
                  <div>
                    {x?.type == "folder" && x?.name != "" && (
                      <div
                        className="flex flex-col"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          display: "flex",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            marginBottom: "-7px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <button
                            style={{ width: "3%" }}
                            folderType={x?.type}
                            folderName={x?.name}
                            onClick={(e) => {
                              if (
                                e?.currentTarget?.getAttribute("folderType") ==
                                "folder"
                              ) {
                                setRouteArray((prevArray) => [
                                  ...prevArray,
                                  e?.currentTarget?.getAttribute("folderName") +
                                    "/",
                                ]);
                                console.log(routeArray);
                              }
                            }}
                          >
                            <img src={folderImage} style={{ width: "40px" }} />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {x?.type == "video" && x?.name != "" && (
                      <div
                        className="flex"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          alignItems: "center",
                          padding: "8px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            width: "3%",
                          }}
                        >
                          <img
                            src={videoIcon}
                            style={{ height: "31px", width: "50px" }}
                          />
                        </div>
                        <div style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {x?.name?.replace("/", "")}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            {x?.type?.replace("/", "").charAt(0).toUpperCase() +
                              x?.type?.replace("/", "").slice(1)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            <button
                              onClick={() => {
                                if (sortType == "normal") setSortType("asc");
                                else if (sortType == "asc") setSortType("desc");
                                else if (sortType === "desc")
                                  setSortType("normal");
                                setSortState("normal");
                                setSortDate("normal");
                              }}
                            >
                              {formatDate(x?.lastModified)}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "24%",
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, x);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {x?.type == "image" &&
                      x?.name != "" &&
                      !x?.name?.includes("-thumb-") && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={x?.downloadUrl}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    {(x?.type == "document" || x?.type == "file") &&
                      x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              height: "100%",
                              width: "3%",
                            }}
                          >
                            <img
                              src={fileIcon}
                              style={{ width: "50px", height: "31px" }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                height: "100%",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
          </div>
        )}
        {sortType == "asc" && (
          <div style={{ width: "100%" }}>
            {listFolderAndFile &&
              listFolderAndFile?.length > 0 &&
              listFolderAndFile
                .sort((a, b) => a.type.localeCompare(b.type))
                .map((x) => (
                  <div>
                    {x?.type == "folder" && x?.name != "" && (
                      <div
                        className="flex flex-col"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          display: "flex",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            marginBottom: "-7px",
                            display: "flex",
                          }}
                        >
                          <button
                            style={{ width: "3%" }}
                            folderType={x?.type}
                            folderName={x?.name}
                            onClick={(e) => {
                              if (
                                e?.currentTarget?.getAttribute("folderType") ==
                                "folder"
                              ) {
                                setRouteArray((prevArray) => [
                                  ...prevArray,
                                  e?.currentTarget?.getAttribute("folderName") +
                                    "/",
                                ]);
                                console.log(routeArray);
                              }
                            }}
                          >
                            <img src={folderImage} style={{ width: "40px" }} />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {x?.type == "video" && x?.name != "" && (
                      <div
                        className="flex"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          alignItems: "center",
                          padding: "8px",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            width: "3%",
                          }}
                        >
                          <img
                            src={videoIcon}
                            style={{ height: "31px", width: "50px" }}
                          />
                        </div>
                        <div style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {x?.name?.replace("/", "")}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            {x?.type?.replace("/", "").charAt(0).toUpperCase() +
                              x?.type?.replace("/", "").slice(1)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            <button
                              onClick={() => {
                                if (sortType == "normal") setSortType("asc");
                                else if (sortType == "asc") setSortType("desc");
                                else if (sortType === "desc")
                                  setSortType("normal");
                                setSortState("normal");
                                setSortDate("normal");
                              }}
                            >
                              {formatDate(x?.lastModified)}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "24%",
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, x);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {x?.type == "image" &&
                      x?.name != "" &&
                      !x?.name?.includes("-thumb-") && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={x?.downloadUrl}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    {(x?.type == "document" || x?.type == "file") &&
                      x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              height: "100%",
                              width: "3%",
                            }}
                          >
                            <img
                              src={fileIcon}
                              style={{ width: "50px", height: "31px" }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                height: "100%",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
          </div>
        )}
        {sortType == "desc" && (
          <div style={{ width: "100%" }}>
            {listFolderAndFile &&
              listFolderAndFile?.length > 0 &&
              listFolderAndFile
                .sort((a, b) => b.type.localeCompare(a.type))
                .map((x) => (
                  <div>
                    {x?.type == "folder" && x?.name != "" && (
                      <div
                        className="flex flex-col"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          display: "flex",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            marginBottom: "-7px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <button
                            style={{ width: "3%" }}
                            folderType={x?.type}
                            folderName={x?.name}
                            onClick={(e) => {
                              if (
                                e?.currentTarget?.getAttribute("folderType") ==
                                "folder"
                              ) {
                                setRouteArray((prevArray) => [
                                  ...prevArray,
                                  e?.currentTarget?.getAttribute("folderName") +
                                    "/",
                                ]);
                                console.log(routeArray);
                              }
                            }}
                          >
                            <img src={folderImage} style={{ width: "40px" }} />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {x?.type == "video" && x?.name != "" && (
                      <div
                        className="flex"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          alignItems: "center",
                          padding: "8px",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            width: "3%",
                          }}
                        >
                          <img
                            src={videoIcon}
                            style={{ height: "31px", width: "50px" }}
                          />
                        </div>
                        <div style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {x?.name?.replace("/", "")}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            {x?.type?.replace("/", "").charAt(0).toUpperCase() +
                              x?.type?.replace("/", "").slice(1)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            <button
                              onClick={() => {
                                if (sortType == "normal") setSortType("asc");
                                else if (sortType == "asc") setSortType("desc");
                                else if (sortType === "desc")
                                  setSortType("normal");
                                setSortState("normal");
                                setSortDate("normal");
                              }}
                            >
                              {formatDate(x?.lastModified)}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "24%",
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, x);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {x?.type == "image" &&
                      x?.name != "" &&
                      !x?.name?.includes("-thumb-") && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={x?.downloadUrl}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    {(x?.type == "document" || x?.type == "file") &&
                      x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              height: "100%",
                              width: "3%",
                            }}
                          >
                            <img
                              src={fileIcon}
                              style={{ width: "50px", height: "31px" }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                height: "100%",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
          </div>
        )}
        {sortDate == "asc" && (
          <div style={{ width: "100%" }}>
            {listFolderAndFile &&
              listFolderAndFile?.length > 0 &&
              listFolderAndFile
                .sort((a, b) =>  (new Date(a.lastModified)) - (new Date(b.lastModified)))
                .map((x) => (
                  <div>
                    {x?.type == "folder" && x?.name != "" && (
                      <div
                        className="flex flex-col"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          display: "flex",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            marginBottom: "-7px",
                            display: "flex",
                          }}
                        >
                          <button
                            style={{ width: "3%" }}
                            folderType={x?.type}
                            folderName={x?.name}
                            onClick={(e) => {
                              if (
                                e?.currentTarget?.getAttribute("folderType") ==
                                "folder"
                              ) {
                                setRouteArray((prevArray) => [
                                  ...prevArray,
                                  e?.currentTarget?.getAttribute("folderName") +
                                    "/",
                                ]);
                                console.log(routeArray);
                              }
                            }}
                          >
                            <img src={folderImage} style={{ width: "40px" }} />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {x?.type == "video" && x?.name != "" && (
                      <div
                        className="flex"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          alignItems: "center",
                          padding: "8px",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            width: "3%",
                          }}
                        >
                          <img
                            src={videoIcon}
                            style={{ height: "31px", width: "50px" }}
                          />
                        </div>
                        <div style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {x?.name?.replace("/", "")}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            {x?.type?.replace("/", "").charAt(0).toUpperCase() +
                              x?.type?.replace("/", "").slice(1)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            <button
                              onClick={() => {
                                if (sortType == "normal") setSortType("asc");
                                else if (sortType == "asc") setSortType("desc");
                                else if (sortType === "desc")
                                  setSortType("normal");
                                setSortState("normal");
                                setSortDate("normal");
                              }}
                            >
                              {formatDate(x?.lastModified)}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "24%",
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, x);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {x?.type == "image" &&
                      x?.name != "" &&
                      !x?.name?.includes("-thumb-") && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={x?.downloadUrl}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    {(x?.type == "document" || x?.type == "file") &&
                      x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              height: "100%",
                              width: "3%",
                            }}
                          >
                            <img
                              src={fileIcon}
                              style={{ width: "50px", height: "31px" }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                height: "100%",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
          </div>
        )}
        {sortDate == "desc" && (
          <div style={{ width: "100%" }}>
            {listFolderAndFile &&
              listFolderAndFile?.length > 0 &&
              listFolderAndFile
                .sort((a, b) =>  (new Date(b.lastModified)) - (new Date(a.lastModified)))
                .map((x) => (
                  <div>
                    {x?.type == "folder" && x?.name != "" && (
                      <div
                        className="flex flex-col"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          display: "flex",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            marginBottom: "-7px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <button
                            style={{ width: "3%" }}
                            folderType={x?.type}
                            folderName={x?.name}
                            onClick={(e) => {
                              if (
                                e?.currentTarget?.getAttribute("folderType") ==
                                "folder"
                              ) {
                                setRouteArray((prevArray) => [
                                  ...prevArray,
                                  e?.currentTarget?.getAttribute("folderName") +
                                    "/",
                                ]);
                                console.log(routeArray);
                              }
                            }}
                          >
                            <img src={folderImage} style={{ width: "40px" }} />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {x?.type == "video" && x?.name != "" && (
                      <div
                        className="flex"
                        style={{
                          margin: "10px",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          borderRadius: "10px",
                          alignItems: "center",
                          padding: "8px",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            width: "3%",
                          }}
                        >
                          <img
                            src={videoIcon}
                            style={{ height: "31px", width: "50px" }}
                          />
                        </div>
                        <div style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {x?.name?.replace("/", "")}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            {x?.type?.replace("/", "").charAt(0).toUpperCase() +
                              x?.type?.replace("/", "").slice(1)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "10px",
                            width: "24%",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "20px",
                              paddingLeft: "10px",
                            }}
                          >
                            <button
                              onClick={() => {
                                if (sortType == "normal") setSortType("asc");
                                else if (sortType == "asc") setSortType("desc");
                                else if (sortType === "desc")
                                  setSortType("normal");
                                setSortState("normal");
                                setSortDate("normal");
                              }}
                            >
                              {formatDate(x?.lastModified)}
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "24%",
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e, x);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {x?.type == "image" &&
                      x?.name != "" &&
                      !x?.name?.includes("-thumb-") && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "3%",
                            }}
                          >
                            <img
                              src={x?.downloadUrl}
                              style={{ height: "31px", width: "50px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    {(x?.type == "document" || x?.type == "file") &&
                      x?.name != "" && (
                        <div
                          className="flex"
                          style={{
                            margin: "10px",
                            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            padding: "9px",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "20px",
                              height: "100%",
                              width: "3%",
                            }}
                          >
                            <img
                              src={fileIcon}
                              style={{ width: "50px", height: "31px" }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "flex-end",
                              fontSize: "20px",
                              paddingLeft: "10px",
                              width: "25%",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                height: "100%",
                              }}
                            >
                              {x?.name?.replace("/", "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              {x?.type
                                ?.replace("/", "")
                                .charAt(0)
                                .toUpperCase() +
                                x?.type?.replace("/", "").slice(1)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginLeft: "10px",
                              width: "24%",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "20px",
                                paddingLeft: "10px",
                              }}
                            >
                              <button
                              // onClick={() => {
                              //   if (sortType == "normal") setSortType("asc");
                              //   else if (sortType == "asc")
                              //     setSortType("desc");
                              //   else if (sortType === "desc")
                              //     setSortType("normal");
                              //   setSortState("normal");
                              //   setSortDate("normal");
                              // }}
                              >
                                {formatDate(x?.lastModified)}
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "24%",
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "10px",
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleClick(e, x);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
          </div>
        )}
      </div>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <>
            {
              <MenuItem
                key={option}
                value={option}
                onClick={(e) => {
                  setAnchorEl(null);
                  let key = e?.currentTarget?.getAttribute("value");
                  if (key === "Delete") {
                    deleteFolder();
                  } else if (key === "Share") {
                    shareFolder();
                  } else if (key === "Rename") {
                    renameFile();
                  } else if (key === "Download") {
                    downloadFile();
                  } else if (key === "Add Thumbnail") {
                    addThumbnail();
                  }
                }}
              >
                {option}
              </MenuItem>
            }
          </>
        ))}
      </Menu> 
      <Snackbar
        open={snackbarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
      <Modal
        open={isLoader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
    </div>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default Folder;
