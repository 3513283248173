import React, {
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function PropertyMap({ properties, filters, setFilters, previousProperty }) {
  console.log(previousProperty);
  console.log(properties);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["drawing"],
  });

  const [map, setMap] = useState(null);
  const [showMarkers, setShowMarkers] = useState(true);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // useEffect(() => {
  //   if (isLoaded && properties.length > 0 && map) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     // properties?.forEach((property) => {
  //     //   if (!isNaN(property.lat) && !isNaN(property.lng)) {
  //     //     bounds.extend(
  //     //       new window.google.maps.LatLng(property.lat, property.lng)
  //     //     );
  //     //   }
  //     // });
  //     map.fitBounds(bounds);
  //   }
  // }, [isLoaded, map]);
  const [drawnPolygon, setDrawnPolygon] = useState(null);
  const [center, setCenter] = useState({ lat: 30.26666, lng: -97.73333 });

  useEffect(() => {
    if (properties && properties.length > 0 && properties[0]?.lat && properties[0]?.lng ) {
      setCenter({
        lat: properties[0]?.lat || previousProperty?.lat || 30.26666,
        lng: properties[0]?.lng || previousProperty?.lng || -97.73333,
      });
    }
    else{
      //setCenter({ lat: 30.26666, lng: -97.73333 } )
    }
  }, [properties]);
  useEffect(() => {
    if (map) {
      const removeAllPolygon = () => {
        if (drawnPolygon && drawnPolygon.length > 0) {
          drawnPolygon.forEach(polygon => {
            polygon.setMap(null);
          });
          setDrawnPolygon(null);
        }
      };
      const updatePolygonPath = (polygon,index) => {
        return () => {
          const path = polygon.getPath().getArray().map(latLng => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));
          setFilters(prevFilters => {
            const newShapes = [...prevFilters.shape];
            newShapes[index] = path;
            return {
              ...prevFilters,
              shape: newShapes
            };
          });;
        };
      };
      // Draw a new polygon
      const drawPolygon = () => {
        removeAllPolygon(); // Clear all previously drawn polygons
        const polygons = filters.shape.map(coordinates => {
          const newPolygon = new window.google.maps.Polygon({
            map: map,
            paths: coordinates.map(point => ({
              lat: Number(point.lat),
              lng: Number(point.lng)
            })),
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: true, 
          });
      
          return newPolygon;
        });
      
        polygons.forEach((polygon, index) => {
          const path = polygon.getPath();
          new window.google.maps.event.addListener(path, 'insert_at', updatePolygonPath(polygon, index));
          new window.google.maps.event.addListener(path, 'set_at', updatePolygonPath(polygon, index));
        });
      
        setDrawnPolygon(polygons);
      };
      

      // Check if there are shapes to draw
      if (filters?.shape && filters?.shape?.length > 0) {
        drawPolygon(); // Then draw the new ones
      } else {
        removeAllPolygon(); // Remove all polygons if shape array is empty
      }

      return () => {
        removeAllPolygon();
      };
    }
  }, [map, filters.shape]);
  

  const handleShapeComplete = (shape) => {
    const path = shape
      .getPath()
      .getArray()
      .map((latlng) => ({
        lat: latlng.lat(),
        lng: latlng.lng(),
      }));
    shape.setMap(null);
    shape.setPath([]);
    // setFilters((prevFilters) => ({ ...prevFilters, shape: path }));
    setFilters((prevFilters) => ({
      ...prevFilters,
      shape: [...prevFilters.shape, path]
    }));
    
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <>
      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
        zoom={11}
      >
        {isLoaded && (
          <>
             {/* {isLoaded && showMarkers && (
              <MarkerClusterer>
                {(clusterer) =>
                  properties.map((property) => (
                    <Marker
                      key={property?.id}
                      position={{ lat: property?.lat, lng: property?.lng }}
                      title={property?.name}
                      clusterer={clusterer}
                    />
                  ))
                }
              </MarkerClusterer>
            )}  */}
            <DrawingManager
              onPolygonComplete={handleShapeComplete}
              options={{
                polygonOptions: {
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                },
              }}
            />
          </>
        )}
      </GoogleMap>
    </>
  );
}

export default PropertyMap;
