import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios"; // Import Axios

const ApartmentUrlFileUploadDropzone = () => {
  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]); // "file" is the field name multer looks for

    axios
      .post(process.env.REACT_APP_API_URL + "/upload-apartment-url", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
        alert("File uploaded successfully");
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        alert("Error uploading file: " + error.response.data.message);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-5">Upload Apartment URL</h2>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #007bff",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag 'n' drop a file here, or click to select a file</p>
        )}
      </div>
    </div>
  );
};

export default ApartmentUrlFileUploadDropzone;
