import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import axios from "axios"; // Import Axios
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import Papa from 'papaparse';
import Modal from '@mui/material/Modal';

const AllFieldsFileUploadDropzone = () => {
  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    const file = acceptedFiles[0];
    if (file) {
      var len = 0;
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          setModalLoader(true);
          const importChucks = array_chunks(results.data, 1);
    
          try {
            for (const items of importChucks) {
              const response = await axios.post(
                process.env.REACT_APP_API_URL + "/upload-chunck-data",
                items
              );
              len++;
              if (len === importChucks.length) {
                alert("File uploaded successfully");
                setModalLoader(false);
              }
            }
          } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            alert("Error uploading file: " + (error.response?.data?.message || error.message));
          }
        },
        error: (parseError) => {
          console.error(parseError);
        },
      });
    }
    
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [modalLoader, setModalLoader] = useState(false);
  const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));
  return (
    <div className="p-4">
      <div>
        <h2 className="text-2xl font-bold mb-5">Update Property</h2>
        <h6
          className="font-bold mb-5"
          style={{ textDecoration: "underline", color: "blue" }}
        >
          <a href="https://pouch.jumpshare.com/download/TzAy8z_6-2SfaidYOFe0gQzzX9ynAF4QV7q8yPT-DXrj0NhDgzHyxMVi6n7U94e_mWzeajnk0eXAOg0a9kqYrA">
            Update Template
          </a>
        </h6>
      </div>
      <Modal open={modalLoader}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress disableShrink />
            </div>
            <div style={{ color: 'white' }}>Import is in progress. Please wait and do not go back.</div>
          </div>
        </Box>
      </Modal>
      {!modalLoader && (
        <div
          {...getRootProps()}
          style={{
            border: "2px dashed #007bff",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <p>Drag 'n' drop a file here, or click to select a file</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AllFieldsFileUploadDropzone;
