import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";
let bool = true;
let bool1 = true;
const ITEM_HEIGHT = 48;
const Image = ({}) => {
  const query = useQuery();
  const iframeRef = useRef(null);
  const [routeArray, setRouteArray] = useState(
    decodeURIComponent(
      decodeURIComponent(atob(query.get("documentUrl")))
        .split("/")
        .map((x, index) => {
          if (
            index ==
            decodeURIComponent(atob(query.get("documentUrl"))).split("/")
              .length -
              1
          ) {
            return x;
          }
          return x + "/";
        })
    )
  );
  const [listFolderAndFile, setListFolderAndFile] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorAddEl, setAnchorAddEl] = React.useState(null);
  const [isLoader, setIsLoader] = React.useState(false);
    React.useState(false);
  const testParam = decodeURIComponent(atob(query.get("documentUrl")));
  // const handleChange = (file) => {
  //   setFile(file);
  // };

  // const handleFolderChange = (event) => {
  //   const files = event.target.files;
  //   const filesArray = Array.from(files);
  //   setSelectedFiles(filesArray);
  // };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const listAll = async () => {
    try {
      setIsLoader(true);
      const url = decodeURIComponent(atob(query.get("documentUrl")));
      const routeTemp = url.split("/").map((x, index, arr) => {
        if (index === arr.length - 1) {
          return x;
        }
        return x + "/";
      });
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/getFileUrl`, {
          folderPath: bool ? testParam : routeTemp,
        })
        .then(async (res) => {
          bool = false;
          setListFolderAndFile(res.data?.items);
          console.log("File uploaded successfully:", res.data);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    listAll();
  }, [routeArray]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      const style = `
        img {
          width: 100%;
          height: 100%;
        } 
      `;
      const styleSheet = iframe.contentDocument.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.appendChild(iframe.contentDocument.createTextNode(style));
      iframe.contentDocument.head.appendChild(styleSheet);
    }
  }, []);


  return (
    <div style={{ width: "100%", height: "100%", position: "absolute" }}>
      {listFolderAndFile != "" && (
        <Helmet>
          <meta charset="utf-8" />
          <meta
            http-equiv="X-UA-Compatible"
            content="IE=edge"
            data-react-helmet="true"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
            data-react-helmet="true"
          />
          <meta property="og:site_name" content="Sidekick" />
          <meta property="og:image" content={listFolderAndFile} />
          <meta name="twitter:card" content="Sidekick" />
          <meta name="twitter:site" content="https://aubreetheagent.com" />
          <meta name="twitter:title" content="Image Preview" />
          <meta name="twitter:description" content="Image Preview" />
          <meta name="twitter:player:width" content="1280" />
          <meta name="twitter:player:height" content="720" />
          {/* Android */}
          <meta property="al:android:url" content={listFolderAndFile} />

          <meta property="al:web:url" content={listFolderAndFile} />
        </Helmet>
      )}
      <img src={listFolderAndFile} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default Image;
