import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Switch from '@mui/material/Switch';

const ProrationCalculator = ({
  isSpecial,
  submit,
  cancel,
  submitStudio,
  monthFee,
  monthFeeReference,
  rentConcessions,
  rentConcessionReferences,
  termLength,
  reduceWidth,
  specialData
}) => {
  console.log(specialData,"test");
  const [referenceBox, setReferenceBox] = useState();
  const [toggle, setToggle] = useState(true);
  const [monthlyRent, setMonthlyRent] = useState();
  const [leaseTerm, setLeaseTerm] = useState(termLength);
  const [monthsFree, setMonthsFree] = useState(monthFee);
  const [monthsFreeReference, setMonthsFreeReference] =
    useState(monthFeeReference);
  const [rentConcession, setRentConcession] = useState(rentConcessions);
  const [rentConcessionReference, setRentConcessionReference] = useState(
    rentConcessionReferences
  );
  const [proratedRentWithConcession, setProratedRentWithConcession] =
    useState("");
  const [proratedRentWithMonthsFree, setProratedRentWithMonthsFree] =
    useState("");
  const [proratedRentWithBoth, setProratedRentWithBoth] = useState("");
  const [leaseTermStudio, setLeaseTermStudio] = useState(specialData?.term_length_studio);
  const [monthsFreeStudio, setMonthsFreeStudio] = useState(specialData?.months_fee_studio);
  const [monthsFreeReferenceStudio, setMonthsFreeReferenceStudio] = useState(specialData?.months_fee_reference_studio);
  const [rentConcessionStudio, setRentConcessionStudio] = useState(specialData?.rent_concession_studio);
  const [rentConcessionReferenceStudio, setRentConcessionReferenceStudio] = useState(specialData?.rent_concession_reference_studio);

  const [leaseTermOneBed, setLeaseTermOneBed] = useState(specialData?.term_length_one_bed);
  const [monthsFreeOneBed, setMonthsFreeOneBed] = useState(specialData?.months_fee_one_bed);
  const [monthsFreeReferenceOneBed, setMonthsFreeReferenceOneBed] = useState(specialData?.months_fee_reference_one_bed);
  const [rentConcessionOneBed, setRentConcessionOneBed] = useState(specialData?.rent_concession_one_bed);
  const [rentConcessionReferenceOneBed, setRentConcessionReferenceOneBed] = useState(specialData?.rent_concession_reference_one_bed);

  const [leaseTermTwoBed, setLeaseTermTwoBed] = useState(specialData?.term_length_two_bed);
  const [monthsFreeTwoBed, setMonthsFreeTwoBed] = useState(specialData?.months_fee_two_bed);
  const [monthsFreeReferenceTwoBed, setMonthsFreeReferenceTwoBed] = useState(specialData?.months_fee_reference_two_bed);
  const [rentConcessionTwoBed, setRentConcessionTwoBed] = useState(specialData?.rent_concession_two_bed);
  const [rentConcessionReferenceTwoBed, setRentConcessionReferenceTwoBed] = useState(specialData?.rent_concession_reference_two_bed);

  const [leaseTermThreeBed, setLeaseTermThreeBed] = useState(specialData?.term_length_three_bed);
  const [monthsFreeThreeBed, setMonthsFreeThreeBed] = useState(specialData?.months_fee_three_bed);
  const [monthsFreeReferenceThreeBed, setMonthsFreeReferenceThreeBed] = useState(specialData?.months_fee_reference_three_bed);
  const [rentConcessionThreeBed, setRentConcessionThreeBed] = useState(specialData?.rent_concession_three_bed);
  const [rentConcessionReferenceThreeBed, setRentConcessionReferenceThreeBed] = useState(specialData?.rent_concession_reference_three_bed);

  const [leaseTermFourPlusBed, setLeaseTermFourPlusBed] = useState(specialData?.term_length_four_bed);
  const [monthsFreeFourPlusBed, setMonthsFreeFourPlusBed] = useState(specialData?.months_fee_four_bed);
  const [monthsFreeReferenceFourPlusBed, setMonthsFreeReferenceFourPlusBed] = useState(specialData?.months_fee_reference_four_bed);
  const [rentConcessionFourPlusBed, setRentConcessionFourPlusBed] = useState(specialData?.rent_concession_four_bed);
  const [rentConcessionReferenceFourPlusBed, setRentConcessionReferenceFourPlusBed] = useState(specialData?.rent_concession_reference_four_bed);
  const preventScroll = (e) => {
    e.target.blur();
  };

  const getLowestValueClass = (value) => {
    const values = [
      proratedRentWithConcession,
      proratedRentWithMonthsFree,
      proratedRentWithBoth,
    ].filter(Boolean);
    const minValue = Math.min(...values.map(Number));
    return minValue === Number(value) ? "text-green-500" : "text-gray-700";
  };

  useEffect(() => {
    // Run the calculation if monthlyRent and leaseTerm are provided
    if (monthlyRent && leaseTerm) {
      calculateProratedRent();
    }
  }, [monthlyRent, leaseTerm, monthsFree, rentConcession]);

  const calculateProratedRent = () => {
    const rent = parseFloat(monthlyRent) || 0;
    const term = parseFloat(leaseTerm) || 0;
    const freeMonths = parseFloat(monthsFree) || 0;
    const concession = parseFloat(rentConcession) || 0;

    if (term === 0 || rent <= 0) {
      // Reset all outputs if inputs are invalid
      setProratedRentWithConcession("");
      setProratedRentWithMonthsFree("");
      setProratedRentWithBoth("");
      return;
    }

    // Calculate prorated rent with concession only
    const totalRentWithConcession = rent * term - concession;
    const proratedWithConcession = totalRentWithConcession / term;

    // Calculate prorated rent with months free only
    const totalRentWithMonthsFree = rent * (term - freeMonths);
    const proratedWithMonthsFree = totalRentWithMonthsFree / term;

    // Calculate prorated rent with both concession and months free
    const totalRentWithBoth = totalRentWithMonthsFree - concession;
    const proratedWithBoth = totalRentWithBoth / term;

    // Update states with the calculated prorated rents
    setProratedRentWithConcession(proratedWithConcession.toFixed(2));
    setProratedRentWithMonthsFree(proratedWithMonthsFree.toFixed(2));
    setProratedRentWithBoth(proratedWithBoth.toFixed(2));
  };
  const handleKeyDown = (e, nextInputId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    }
  };

  const handleClickSubmit = () => {
    submit(
      {},
      leaseTerm,
      monthsFree,
      rentConcession,
      monthsFreeReference,
      rentConcessionReference,
      true
    );
  };

  const handleClickCancel = () => {
    cancel(false);
  };

  return (
    <div className={`p-5  ${(!isSpecial || toggle ) ? "max-w-sm mx-auto" : ''}`}>
      {(!isSpecial || toggle) && (
        <div>
          {!isSpecial && (
            <div className="m-1 flex flex-col">
              <label className="block text-gray-700 text-sm font-bold">
                Rent /Month
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-gray-400  focus:shadow-outline"
                id="monthlyRent"
                type="number"
                placeholder="Monthly Rent"
                value={monthlyRent}
                onChange={(e) => setMonthlyRent(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "leaseTerm")}
              />
            </div>
          )}
          <div className="m-1 flex flex-col">
            <label className="block text-gray-700 text-sm font-bold">
              Term Length
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-gray-400 focus:shadow-outline"
              id="leaseTerm"
              type="number"
              placeholder="Lease Term"
              value={leaseTerm}
              onChange={(e) => setLeaseTerm(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, "monthsFree")}
            />
          </div>
          <div style={{ width: "100%", display: "flex", alignItems: "end" }}>
            <div
              className="m-1 flex flex-col"
              style={{ width: isSpecial ? "30%" : "100%" }}
            >
              <label className="block text-gray-700 text-sm font-bold">
                Months Free
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-gray-400 focus:shadow-outline"
                id="monthsFree"
                type="number"
                placeholder="Months Free"
                step="any"
                value={monthsFree}
                onChange={(e) => setMonthsFree(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "rentConcession")}
              />
            </div>
            {isSpecial && (
              <div className="m-1 flex flex-col" style={{ width: "70%" }}>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-gray-400 focus:shadow-outline"
                  id="monthsFree"
                  type="text"
                  step="any"
                  value={monthsFreeReference}
                  onChange={(e) => setMonthsFreeReference(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, "rentConcession")}
                />
              </div>
            )}
          </div>
          <label className="m-1 block text-gray-700 text-sm font-bold">
            Rent Concession
          </label>
          <div style={{ width: "100%", display: "flex", alignItems: "end" }}>
            <div
              className="m-1 flex flex-col mb-3"
              style={{ width: isSpecial ? "30%" : "100%" }}
            >
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-gray-400 focus:shadow-outline"
                id="rentConcession"
                type="number"
                placeholder="Rent Concession"
                value={rentConcession}
                onChange={(e) => setRentConcession(e.target.value)}
              />
            </div>
            {isSpecial && (
              <div className="m-1 flex flex-col mb-3" style={{ width: "70%" }}>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-gray-400 focus:shadow-outline"
                  id="rentConcession"
                  type="text"
                  value={rentConcessionReference}
                  onChange={(e) => setRentConcessionReference(e.target.value)}
                />
              </div>
            )}
          </div>
          <hr />
          {!isSpecial && (
            <div className="mt-3">
              <label
                className="block text-gray-700 text-sm font-bold"
                htmlFor="proratedRentConcession"
              >
                Prorated Rent with Concession
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 ${getLowestValueClass(
                  proratedRentWithConcession
                )} bg-white leading-tight focus:outline-none focus:shadow-outline`}
                id="proratedRentConcession"
                type="text"
                value={proratedRentWithConcession}
                disabled
              />
            </div>
          )}

          {!isSpecial && (
            <div>
              <label
                className="block text-gray-700 text-sm font-bold"
                htmlFor="proratedRentMonthsFree"
              >
                Prorated Rent with Months Free
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 ${getLowestValueClass(
                  proratedRentWithMonthsFree
                )} bg-white leading-tight focus:outline-none focus:shadow-outline`}
                id="proratedRentMonthsFree"
                type="text"
                value={proratedRentWithMonthsFree}
                disabled
              />
            </div>
          )}

          {!isSpecial && (
            <div>
              <label
                className="block text-gray-700 text-sm font-bold"
                htmlFor="proratedRentBoth"
              >
                Prorated Rent with Both Applied
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 ${getLowestValueClass(
                  proratedRentWithBoth
                )} bg-white leading-tight focus:outline-none focus:shadow-outline`}
                id="proratedRentBoth"
                type="text"
                value={proratedRentWithBoth}
                disabled
              />
            </div>
          )}

          {isSpecial && (
            <div className="mt-5 flex justify-between">
              <Button
                variant="contained"
                onClick={handleClickSubmit}
                disabled={
                  leaseTerm ? false : true
                }
              >
                Submit
              </Button>
              <Button variant="contained" onClick={handleClickCancel}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProrationCalculator;
